import GuestProtect from "../components/Auth/GuestProtect";
import AuthProtect from "../components/Auth/AuthProtect";
import { Switch, Route, Redirect } from "react-router-dom";
import React, { Suspense, Fragment, lazy } from "react";
import LandingContainer from "../containers/LandingContainer";

function RouteProgress(props) {
  return <Route {...props} />;
}

export function renderRoutes(routes = []) {
  return (
    <Suspense fallback={<></>}>
      <Switch>
        {routes.map((route, i) => {
          const Component = route.component;
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;

          return (
            <RouteProgress
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
}

const routes = [
  // Others Routes
  {
    exact: true,
    guard: GuestProtect,
    layout: LandingContainer,
    path: "/login",
    component: lazy(() => import("../views/auth/LoginView")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: "/app",
    component: lazy(() => import("../views/home/InstrumentsPage")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: "/sampleprofile_create",
    component: lazy(() => import("../views/SampleProfileCreate")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: "/sampleprofile",
    component: lazy(() => import("../views/SampleProfile")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: "/node-preset-create",
    component: lazy(() => import("../views/NodePresetCreate")),
  },
  {
    exact: true,
    guard: AuthProtect,
    path: "/node-preset",
    component: lazy(() => import("../views/NodePresets")),
  },
  {
    exact: true,
    path: "/",
    component: () => <Redirect to={"/login"} />,
  },
];

export default routes;
